import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventDateStatusEnum } from '@shared/enums/event-date-status.enum';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';

@Component({
  selector: 'app-event-status-select',
  templateUrl: './event-status-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventStatusSelectComponent {

  @Input() status!: EventDateStatusEnum;
  @Input() eventDate!: EventDateResponseModel;
  @Output() statusChanged = new EventEmitter<{eventDate: EventDateResponseModel, status: EventDateStatusEnum}>();

  EventDateStatusEnum = EventDateStatusEnum;

  get statusClass() {
    return {
      'ant-select-on-sale': this.status === EventDateStatusEnum.ON_SALE,
      'ant-select-off-sale': this.status === EventDateStatusEnum.OFF_SALE,
      'ant-select-cancelled': this.status === EventDateStatusEnum.CANCELLED
    };
  }

  onStatusChange(newStatus: EventDateStatusEnum) {
    if (this.status === newStatus) return;
    this.statusChanged.emit({ eventDate: this.eventDate, status: newStatus });
  }
}
