<nz-drawer nzPlacement="right" [nzTitle]="titleTpl"
    [nzClosable]="false" 
    [nzVisible]="isDrawerVisible"
    (nzOnClose)="close()"
    [nzFooter]="footerTpl"
    [nzWidth]="500">
    <ng-container *nzDrawerContent>
        <div class="tnt-drawer-schedule">
            <form nz-form [formGroup]="scheduleForm" nzLayout="vertical">
                <div class="tnt-drawer-schedule__content">


                    <div class="tnt-drawer-schedule__title">Event Visibility</div>
                    <nz-divider></nz-divider>

                    <div class="tnt-drawer-schedule__subtitle">
                        Who can book this event?
                    </div>

                    <div nz-row>
                        <div nz-col nzSpan="24">
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-radio-group formControlName="visibility">
                                        <label nz-radio-button [nzValue]="EventVisibilityEnum.PUBLIC">Public Event</label>
                                        <label nz-radio-button [nzValue]="EventVisibilityEnum.PRIVATE">Private Event</label>
                                    </nz-radio-group>
                                </nz-form-control>
                            </nz-form-item>
    
                            <ng-container *ngIf="scheduleForm.controls['visibility']?.value === EventVisibilityEnum.PUBLIC">
                                <div>
                                    Public events can be booked by multiple, unconnected parties.
                                </div>
                            </ng-container>
    
                            <ng-container *ngIf="scheduleForm.controls['visibility']?.value === EventVisibilityEnum.PRIVATE">
                                <div>
                                    A private event is an exclusive booking for a single party e.g. Private Birthday Party. Only one group can book the event, ensuring exclusivity.
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <br/>

                    <div class="tnt-drawer-schedule__title">Date and time</div>
                    <nz-divider></nz-divider>

                    <div class="tnt-drawer-schedule__subtitle">
                        When does your event start, and at what time?
                    </div>

                    <div nz-row>
                        <div nz-col nzSpan="24">
                            <nz-form-item>
                                <nz-form-label>Start date</nz-form-label>
                                <nz-form-control nzErrorTip="Please input event start date!">
                                    <nz-date-picker #startDate formControlName="startDate" nzSize="large" [nzAllowClear]="false" (ngModelChange)="onStartDateChange()"
                                        nzFormat="dd/MM/yyyy"></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row nzGutter="24">
                        <div nz-col nzSpan="24">
                            <nz-form-item>
                                <nz-form-label>Start time</nz-form-label>
                                <nz-form-control nzErrorTip="Please input event start time!">
                                    <nz-time-picker formControlName="startTime" nzSize="large" nzFormat="HH:mm"></nz-time-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row nzGutter="24">
                        <div nz-col nzSpan="24">
                            <nz-form-item>
                                <nz-form-label>Period</nz-form-label>
                                <nz-form-control nzErrorTip="Please input event period!">
                                    <nz-select formControlName="period" nzSize="large" (ngModelChange)="onPeriodChange($event)">
                                        <nz-option *ngFor="let period of EventPeriodEnum | keyvalue: originalOrder" [nzLabel]="period.key" [nzValue]="period.value"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
    
                    <div nz-row nzGutter="24" *ngIf="scheduleForm.controls['period'].value === EventPeriodEnum.MONTHLY">
                        <div nz-col nzSpan="24">
                            <nz-form-item>
                                <nz-form-label>Options</nz-form-label>
                                <nz-form-control nzErrorTip="Please input event period!">
                                    <nz-select formControlName="monthlyPeriod" nzSize="large">
                                        <nz-option *ngFor="let period of monthlyPeriodOptions" [nzLabel]="period.label" [nzValue]="period"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
    
                    <ng-container *ngIf="scheduleForm.controls['period'].value !== EventPeriodEnum.ONCE">
                        
                        <div nz-row nzJustify="space-around" class="tnt-drawer-schedule__content__day-view">
                            <div nz-col nzSpan="3" *ngFor="let day of days">
                                <button nz-button [nzType]="day.isSelected ? 'primary' : 'default'" nzShape="circle"
                                    nzSize="small">{{ day.name }}</button>
                            </div>
                        </div>
                                
                        <nz-divider></nz-divider>
    
                        <h3>Ending</h3>

                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <nz-form-item>
                                    <nz-form-label>End date</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input event end date!">
                                        <nz-date-picker formControlName="endDate" nzSize="large" nzFormat="dd/MM/yyyy"
                                            [nzDisabledDate]="disabledBeforeStartDate"></nz-date-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>

                        <!--
                        <div nz-row class="tnt-drawer-schedule__content__end--after">
                            <div nz-col nzSpan="24">
                                <nz-radio-group formControlName="endType">
                                    <label nz-radio>
                                        <div nz-row nzGutter="16">
                                            <div nz-col nzSpan="4">
                                                <div class="tnt-drawer-schedule__content__end--after__label">On</div>
                                            </div>
                                            <div nz-col nzSpan="12">
                                                <nz-form-control>
                                                    <nz-date-picker formControlName="endDate" nzSize="large" nzFormat="dd/MM/yyyy"></nz-date-picker>
                                                </nz-form-control>
                                            </div>
                                        </div>
                                    </label>
                                    <label nz-radio>
                                        <div nz-row nzGutter="16">
                                            <div nz-col nzSpan="4">
                                                <div class="tnt-drawer-schedule__content__end--after__label">After</div>
                                            </div>
                                            <div nz-col nzSpan="12">
                                                <nz-form-item>
                                                    <nz-form-control>
                                                        <nz-input-number nzSize="large" formControlName="endNumber" [nzStep]="1" nzPlaceHolder="Number of events"></nz-input-number>
                                                    </nz-form-control>
                                                </nz-form-item>
                                            </div>
                                            <div nz-col nzSpan="6">
                                                <div class="tnt-drawer-schedule__content__end--after__label">events</div>
                                            </div>
                                        </div>
                                    </label>
                                </nz-radio-group>
                            </div>
                        </div>
                        -->
                    </ng-container>
                </div>
            </form>
        </div>
        
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-drawer-schedule__footer">
            <div nz-row nzJustify="center" nzGutter="12">
                <div nz-col nzSpan="8">
                    <button nz-button nzSize="large" nzType="default" (click)="close()">Cancel</button>
                </div>
                <div nz-col nzSpan="8">
                    <button nz-button nzSize="large" nzType="primary" (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #titleTpl>
        <div nz-row class="tnt-widget-table__header">
            <div nz-col nzSpan="22">
                <i class="fa-solid fa-calendar-days"></i> Add Dates
            </div>
            <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
                <span (click)="close()">x</span>
            </div>
        </div>
    </ng-template>
</nz-drawer>
