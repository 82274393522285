import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DateTimeUtil } from '@shared/utils/date-time-util';
import { validateFormGroup } from '@shared/utils/form.util';

import { EventDateService } from '@shared/services/event-date.service';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { EventDateUpdateRequestModel } from '@shared/models/http/request/event-date/event-date-update-request.model';

import * as moment from 'moment-timezone';
import { EventDateStatusEnum } from '@shared/enums/event-date-status.enum';

export enum EventDateVisibilityEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

@UntilDestroy()
@Component({
  selector: 'app-event-date-edit-drawer',
  templateUrl: './event-date-edit-drawer.component.html',
})
export class EventDateEditDrawerComponent implements OnDestroy, OnInit {

  @Output() public onEventDateUpdate = new EventEmitter<EventDateResponseModel>();

  Math = Math;

  UserRoleEnum = UserRoleEnum;
  eventDateForm!: UntypedFormGroup;

  EventDateStatusEnum = EventDateStatusEnum;
  EventDateVisibilityEnum = EventDateVisibilityEnum;
  
  eventDate?: EventDateResponseModel;

  isDrawerVisible = false;
  isUpdatingEventDate = false;

  constructor(
    private fb: UntypedFormBuilder,
    private eventDateService: EventDateService
  ) { }

  ngOnInit(): void {
    this.eventDateForm = this.fb.group({
      venueName: [null],
      venueCity: [null],
      capacity: [null],
      notes: [null],
      status: [null, [Validators.required]],
      visibility: [EventDateVisibilityEnum.PUBLIC, [Validators.required]],
      dateTime: this.fb.group({
        date: [null, [Validators.required]],
        time: [null, [Validators.required]],
      }),
      alert: this.fb.group({
        status: [false, [Validators.required]],
        value: [1, [Validators.pattern('[1-9][0-9]*')]]
      })
    });
  }

  open(eventDate: EventDateResponseModel) {
    this.eventDate = eventDate;
    
    // Set Form
    this.eventDateForm.reset();
    this.patchToEventDateForm(eventDate);
    this.onAlertSwitchChanged(this.eventDate.isAlertOn);
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  patchToEventDateForm(eventDate: EventDateResponseModel) {
    const t = moment.utc(eventDate.start);
    const normalizedDate = new Date(t.year(), t.month(), t.date(), t.hour(), t.minute(), 0);

    const visibility = eventDate.isPrivate ? EventDateVisibilityEnum.PRIVATE : EventDateVisibilityEnum.PUBLIC;
    const capacity = eventDate.capacity ?? (eventDate.event.capacity === -1 ? undefined : eventDate.event.capacity);
    const eventDateForm: any = {
      status: eventDate.status,
      venueName: eventDate?.venueName ?? eventDate.event.venue?.name,
      venueCity: eventDate?.venueCity ?? eventDate.event.venue?.city,
      notes: eventDate.notes ?? '',
      capacity: capacity,
      visibility: visibility,
      dateTime: {
        date: normalizedDate,
        time: normalizedDate
      },
      alert: {
        status: eventDate.isAlertOn,
        value: eventDate.alertAt
      }
    };
    this.eventDateForm.patchValue(eventDateForm);
  }

  onUpdateClick() {
    this.updateEventDate();
  }

  updateEventDate() {
    validateFormGroup(this.eventDateForm);
    if (this.eventDateForm.invalid) {
      return;
    }

    this.isUpdatingEventDate = true;
    const eventDateUpdateRequestModel = this.generateEventDateUpdateRequestModel();
    this.eventDateService.update(this.eventDate!.id, eventDateUpdateRequestModel).pipe(untilDestroyed(this))
      .subscribe((eventDate) => {
        this.eventDate = eventDate;
        this.onEventDateUpdate.emit(this.eventDate);
        this.isUpdatingEventDate = false;
        this.isDrawerVisible = false;
      }, err => {
        this.isUpdatingEventDate = false;
        console.log('Error while updating Event Date. Error : ', err);
      });
  }

  generateEventDateUpdateRequestModel(): EventDateUpdateRequestModel {
    const eventDateForm = this.eventDateForm.value;
    const momentDate = moment(eventDateForm.dateTime.date);
    const momentTime = moment(eventDateForm.dateTime.time);
    const startDateTime = DateTimeUtil.setMomentTimeOption(momentDate, momentTime);

    const eventDateUpdateRequestModel: EventDateUpdateRequestModel = {
      status: eventDateForm.status,
      start: startDateTime.toDate(),
      end: undefined,
      isPrivate: eventDateForm.visibility !== EventDateVisibilityEnum.PUBLIC,
      isAlertOn: eventDateForm.alert.status,
      alertAt: (!eventDateForm.alert.value || eventDateForm.alert.value === 0) ? -1 : eventDateForm.alert.value,
      notes: eventDateForm.notes ? eventDateForm.notes : '',
      venueName: eventDateForm.venueName,
      venueCity: eventDateForm.venueCity,
      capacity: eventDateForm.capacity
    }
    return eventDateUpdateRequestModel;
  }

  onAlertSwitchChanged(status: boolean) {
    const alertForm = this.eventDateForm.controls['alert'];
    if (status) {
      (alertForm as UntypedFormGroup).controls['value'].addValidators([Validators.required]);
      (alertForm as UntypedFormGroup).controls['value'].updateValueAndValidity();
    } else {
      (alertForm as UntypedFormGroup).controls['value'].clearValidators();
      (alertForm as UntypedFormGroup).controls['value'].updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {

  }
}
