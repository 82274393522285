<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="720" nzPlacement="right" [nzTitle]="titleTempRef"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="tnt-drawer-event-edit">
            <form nz-form [formGroup]="eventDateForm" nzLayout="vertical">

                <div class="tnt-drawer-event-edit__title">
                    {{ eventDate?.event?.name }}
                </div>
                <nz-divider></nz-divider>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__subtitle">
                            Which venue will your event be held at? If you aren’t sure, leave blank.
                        </div>
        
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venueName" placeholder="Venue" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="tnt-drawer-event-edit__subtitle">
                            Which city will your event be held at? If you aren’t sure, leave blank.
                        </div>     

                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venueCity" placeholder="City" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="tnt-drawer-event-edit__subtitle">
                            Additional information and notes.
                        </div>    

                        <nz-form-item>
                            <nz-form-control>
                                <textarea nz-input formControlName="notes" placeholder="Notes" 
                                    [nzAutosize]="{ minRows: 3, maxRows: 3 }" nzSize="large"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <!-- Event Status Section -->
                <div class="tnt-drawer-event-edit__title">Event Status</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    What is the current status of the event?
                </div>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-radio-group formControlName="status">
                                    <label nz-radio-button [nzValue]="EventDateStatusEnum.ON_SALE">On-Sale</label>
                                    <label nz-radio-button [nzValue]="EventDateStatusEnum.OFF_SALE">Off-Sale</label>
                                    <label nz-radio-button [nzValue]="EventDateStatusEnum.CANCELLED">Cancelled</label>
                                </nz-radio-group>
                            </nz-form-control>
                        </nz-form-item>

                        <!-- Contextual Information Based on Selection -->
                        <ng-container *ngIf="eventDateForm.controls['status']?.value === EventDateStatusEnum.ON_SALE">
                            <div>
                                "On-Sale" status indicates that the event is currently available for booking.
                            </div>
                        </ng-container>

                        <ng-container *ngIf="eventDateForm.controls['status']?.value === EventDateStatusEnum.OFF_SALE">
                            <div>
                                "Off-Sale" status indicates that the event is temporarily unavailable for booking.
                            </div>
                        </ng-container>

                        <ng-container *ngIf="eventDateForm.controls['status']?.value === EventDateStatusEnum.CANCELLED">
                            <div>
                                "Cancelled" status means the event is no longer active or available for booking.
                            </div>
                        </ng-container>
                    </div>
                </div>

                <br/>

                <!-- Event Visibility Section -->
                <div class="tnt-drawer-event-edit__title">Event Visibility</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    Who can book this event?
                </div>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-radio-group formControlName="visibility">
                                    <label nz-radio-button [nzValue]="EventDateVisibilityEnum.PUBLIC">Public Event</label>
                                    <label nz-radio-button [nzValue]="EventDateVisibilityEnum.PRIVATE">Private Event</label>
                                </nz-radio-group>
                            </nz-form-control>
                        </nz-form-item>

                        <!-- Contextual Information Based on Selection -->
                        <ng-container *ngIf="eventDateForm.controls['visibility']?.value === EventDateVisibilityEnum.PUBLIC">
                            <div>
                                Public events can be booked by multiple, unconnected parties.
                            </div>
                        </ng-container>
        
                        <ng-container *ngIf="eventDateForm.controls['visibility']?.value === EventDateVisibilityEnum.PRIVATE">
                            <div>
                                A private event is an exclusive booking for a single party e.g. Private Birthday Party. Only one group can book the event, ensuring exclusivity.
                            </div>
                        </ng-container>

                        <br/>
                    </div>
                </div>

                <div class="tnt-drawer-event-edit__title">Date and time</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    When does your event start, and at what time?
                </div>

                <div nz-row nzGutter="24" formGroupName="dateTime">
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input date and time!">
                                <nz-date-picker formControlName="date" nzSize="large" nzFormat="dd/MM/yyyy"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-time-picker formControlName="time" nzSize="large" nzFormat="HH:mm"></nz-time-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="tnt-drawer-event-edit__title">Capacity & Sold Out Alert</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    What’s your events capacity and set up alerting if want to know when you have nearly sold out.
                </div>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-input-number nzSize="large" formControlName="capacity" [nzStep]="1" nzPlaceHolder="Number of tickets"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="tnt-drawer-event-edit__subtitle">
                    Alert when {{ eventDateForm.controls['alert']!.get('value')!.value ? eventDateForm.controls['alert']!.get('value')!.value : 0 }} tickets are sold.
                </div>

                <div nz-row formGroupName="alert">
                    <div nz-col nzFlex="60px">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-switch formControlName="status" (ngModelChange)="onAlertSwitchChanged($event)"></nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzMd="8" nzFlex="auto">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-input-number nzSize="large" formControlName="value" [nzStep]="1" nzPlaceHolder="Number of tickets"
                                [nzDisabled]="!eventDateForm.controls['alert']!.get('status')!.value"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

            </form>
        </div>
        
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <button nz-button nzType="primary" [nzLoading]="isUpdatingEventDate" (click)="onUpdateClick()">Update</button>
        </div>
      </ng-template>
</nz-drawer>

<ng-template #titleTempRef>
    <div nz-row class="tnt-widget-table__header">
        <div nz-col nzSpan="22">
            <i class="fas fa-edit"></i> Edit Event Date
        </div>
        <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
            <span (click)="close()">x</span>
        </div>
    </div>
</ng-template>


