export class EventDateCreateMultipleRequestModel {

    constructor(eventId: number, dates: DateModel[] = [], isPrivate: boolean = false, isAlertOn: boolean = false, alertAt: number = -1) {
        this.eventId = eventId;
        this.dates = dates;
        this.isPrivate = isPrivate;
        this.isAlertOn = isAlertOn;
        this.alertAt = alertAt;
    }

    eventId!: number;
    dates!: DateModel[];
    isPrivate!: boolean;
    isAlertOn!: boolean;
    alertAt!: number;
}

export class DateModel {
    constructor(start: Date, end?: Date) {
        this.start = start;
        this.end = end;
    }

    start!: Date;
    end?: Date;
}
