import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { EventDateService } from '@shared/services/event-date.service';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { EventDateUpdateRequestModel } from '@shared/models/http/request/event-date/event-date-update-request.model';

@UntilDestroy()
@Component({
  selector: 'app-event-date-note-drawer',
  templateUrl: './event-date-note-drawer.component.html',
})
export class EventDateNoteDrawerComponent implements OnInit {

  @Output() public onEventDateUpdate = new EventEmitter<EventDateResponseModel>();

  UserRoleEnum = UserRoleEnum;
  eventDateNoteForm!: UntypedFormGroup;
  eventDate!: EventDateResponseModel;

  isDrawerVisible = false;
  isEventDateUpdating = false;
  
  constructor(
    private fb: UntypedFormBuilder,
    private eventDateService: EventDateService
  ) { }

  ngOnInit(): void {
    this.eventDateNoteForm = this.fb.group({
      note: [null]
    });
  }

  open(eventDate: EventDateResponseModel) {
    this.eventDate = eventDate;
    this.eventDateNoteForm.reset();
    this.eventDateNoteForm.patchValue({
      note: eventDate.notes
    });
    this.isDrawerVisible = true;
  }

  handleOk()  {
    this.isEventDateUpdating = true;
    const eventDateUpdateRequestModel = this.generateEventDateUpdateRequestModel();
    this.eventDateService.update(this.eventDate.id, eventDateUpdateRequestModel).pipe(untilDestroyed(this))
      .subscribe((eventDate) => {
        this.onEventDateUpdate.emit(eventDate);
        this.isEventDateUpdating = false;
        this.isDrawerVisible = false;
      }, err => {
        this.isEventDateUpdating = false;
        console.log('Error while setting Event Note Note. Error : ', err);
      });
  }

  generateEventDateUpdateRequestModel(): EventDateUpdateRequestModel {
    const eventDateNote = this.eventDateNoteForm.value;
    const eventDateUpdateRequestModel: EventDateUpdateRequestModel = {
      status: this.eventDate!.status,
      start: this.eventDate.start,
      end: this.eventDate.end,
      isAlertOn: this.eventDate.isAlertOn,
      alertAt: this.eventDate.alertAt ?? -1,
      notes: eventDateNote.note,
      venueName: this.eventDate.venueName,
      venueCity: this.eventDate.venueCity,
      capacity: this.eventDate.capacity
    }
    return eventDateUpdateRequestModel;
  }

  handleCancel() {
    this.isDrawerVisible = false;
  }
}
