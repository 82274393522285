import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// 3rd Party ::: NG-Zorro Module
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzUploadModule } from 'ng-zorro-antd/upload';

// 3rd Party ::: Other Modules
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Shared Components
import { BackButtonComponent } from './components/common/back-button/back-button.component';
import { SplashScreenComponent } from './components/common/splash-screen/splash-screen.component';
import { ConfirmationBarComponent } from './components/common/confirmation-bar/confirmation-bar.component';
import { EllipsisPopoverComponent } from './components/common/ellipsis-popover/ellipsis-popover.component';
import { EventStatusSelectComponent } from './components/common/event-status-select/event-status-select.component';
// Shared Components Drawers
import { EmailDrawerComponent } from './components/drawers/email-drawer/email-drawer.component';
import { EventEditDrawerComponent } from './components/drawers/event/event-edit-drawer/event-edit-drawer.component';
import { EventDateNoteDrawerComponent } from './components/drawers/event/event-date-note-drawer/event-date-note-drawer.component';
import { EventDateListDrawerComponent } from './components/drawers/event/event-date-list-drawer/ event-date-list-drawer.component';
import { EventDateEditDrawerComponent } from './components/drawers/event/event-date-edit-drawer/event-date-edit-drawer.component';
import { EventDateBulkEditDrawerComponent } from './components/drawers/event/event-date-bulk-edit-drawer/event-date-bulk-edit-drawer.component';
import { BookingDrawerComponent } from './components/drawers/booking/booking-drawer/booking-drawer.component';
import { BookingMoveDrawerComponent } from './components/drawers/booking/booking-move-drawer/booking-move-drawer.component';
import { BookingMergeDrawerComponent } from './components/drawers/booking/booking-merge-drawer/booking-merge-drawer.component';
import { EventDateScheduleDrawerComponent } from './components/drawers/event/event-date-schedule-drawer/event-date-schedule-drawer.component';
import { ChannelAssignToEventDateDrawerComponent } from './components/drawers/channel/channel-assign-to-event-date-drawer/channel-assign-to-event-date-drawer.component';
// Shared Components Modals
import { EventDateNoteModalComponent } from './components/modals/event-date-note-modal/event-date-note-modal.component';

// Pips
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { DateWithTimezonePipe } from './pipes/date-with-timezone.pipe';

const NGZORROMODULS = [
  NzLayoutModule,
  NzFormModule,
  NzInputModule,
  NzButtonModule,
  NzSelectModule,
  NzIconModule,
  NzDropDownModule,
  NzCheckboxModule,
  NzCardModule,
  NzMessageModule,
  NzSpinModule,
  NzTabsModule,
  NzTableModule,
  NzTagModule,
  NzEmptyModule,
  NzModalModule,
  NzAlertModule,
  NzDividerModule,
  NzRadioModule,
  NzDatePickerModule,
  NzTimePickerModule,
  NzCalendarModule,
  NzDrawerModule,
  NzProgressModule,
  NzBackTopModule,
  NzInputNumberModule,
  NzSwitchModule,
  NzToolTipModule,
  NzBadgeModule,
  NzPopconfirmModule,
  NzListModule,
  NzStepsModule,
  NzTimelineModule,
  NzSkeletonModule,
  NzTypographyModule,
  NzSpaceModule,
  NzBreadCrumbModule,
  NzPopoverModule,
  NzPipesModule,
  NzUploadModule
]

const THIRDMODULES = [
  ...NGZORROMODULS,
  CKEditorModule,
  NgxChartsModule,
  NgxPermissionsModule,
  DragDropModule
];

// Components & directives
const COMPONENTS: any[] = [
  BackButtonComponent,
  SplashScreenComponent,
  ConfirmationBarComponent,
  EllipsisPopoverComponent,
  BookingDrawerComponent,
  BookingMergeDrawerComponent,
  BookingMoveDrawerComponent,
  EventEditDrawerComponent,
  EventDateNoteDrawerComponent,
  EventDateListDrawerComponent,
  EventDateEditDrawerComponent,
  EventDateBulkEditDrawerComponent,
  EventDateScheduleDrawerComponent,
  EventEditDrawerComponent,
  EmailDrawerComponent,
  ChannelAssignToEventDateDrawerComponent,
  EventDateNoteModalComponent,
  EventStatusSelectComponent
];

const DIRECTIVES: any[] = [];
// End Components & directives

// Pipes
const PIPES: any[] = [
  SplitCamelCasePipe,
  DateWithTimezonePipe
];
// End Pipes

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        // third libs
        ...THIRDMODULES
    ],
    declarations: [
        // components and directives
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        // third libs
        ...THIRDMODULES,
        // components and directives
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ]
})
export class SharedModule { }
